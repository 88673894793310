import React, { useState, useEffect } from 'react';
import { Button, TextField, FormControl, MenuItem, Select, InputLabel, Typography, Box, Card, CardContent, Grid } from '@mui/material';

// Function to strip HTML tags from a string
const stripHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState('1w');
  const [webResults, setWebResults] = useState([]);
  const [videoResults, setVideoResults] = useState([]);
  const [newsResults, setNewsResults] = useState([]);
  const [isSearchLocked, setIsSearchLocked] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  // Countdown timer for search lock
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    } else {
      setIsSearchLocked(false);
    }
  }, [timeLeft]);

  // Handle search functionality
  const handleSearch = async (event) => {
    event.preventDefault();

    if (!searchTerm) {
      alert('Please enter a search term.');
      return;
    }

    setIsSearchLocked(true);
    setTimeLeft(60);
    setErrorMessage('');

    try {
      const freshness = dateRange === '1d' ? 'pd' :
                        dateRange === '1w' ? 'pw' :
                        dateRange === '1m' ? 'pm' :
                        dateRange === '6m' ? 'py' : '';

      const apiUrl = `https://wtn-brave-worker.sthoms105.workers.dev/?q=${encodeURIComponent(searchTerm)}&count=20&freshness=${freshness}&result_filter=web,news,videos`;
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error('Failed to fetch data. Status: ' + response.status);
      }

      const data = await response.json();

      // Clean up web results and extract both publish and update dates
      const extractedWebResults = data.web?.results?.map(result => ({
        title: stripHtml(result.title) || 'No title available',
        url: result.url || '#',
        description: stripHtml(result.description) || 'No description available',
        age: result.age || 'Unknown publish date',  // Publish date
        fetchedDate: result.page_fetched || 'Unknown update date',  // Recently updated/re-indexed date
      })) || [];

      const extractedVideoResults = data.videos?.results.map(video => ({
        url: video.url,
        title: video.title,
        thumbnail: video.thumbnail?.src,
      })) || [];

      const extractedNewsResults = data.news?.results.map(news => ({
        title: news.title,
        url: news.url,
      })) || [];

      setWebResults(extractedWebResults);
      setVideoResults(extractedVideoResults);
      setNewsResults(extractedNewsResults);

      if (extractedWebResults.length === 0 && extractedVideoResults.length === 0 && extractedNewsResults.length === 0) {
        setErrorMessage('No search results found.');
      }

    } catch (error) {
      setErrorMessage('Error fetching data: ' + error.message);
    }
  };

  // Display web results with both publish and update dates
  const displayWebResults = () => {
    return webResults.length > 0 ? (
      webResults.map((result, index) => (
        <li key={index} className="list-group-item">
          <a href={result.url} target="_blank" rel="noopener noreferrer">
            <Typography variant="body1">{result.title}</Typography>
            <Typography variant="body2">{result.description}</Typography>
            <Typography variant="caption">Published on: {result.age}</Typography>
            {/* Provide a more descriptive message for unknown update date */}
            <Typography variant="caption" style={{ display: 'block', marginTop: '8px', marginLeft: '16px' }}>
              {result.fetchedDate !== 'Unknown update date' ? `Last updated on: ${result.fetchedDate}` : 'Last update not available'}
            </Typography>
          </a>
        </li>
      ))
    ) : (
      <Typography variant="body1" color="textSecondary" align="center">
        No web results found.
      </Typography>
    );
  };

  // Display video results (only title and thumbnail)
  const displayVideoResults = () => {
    return videoResults.length > 0 ? (
      videoResults.map((video, index) => (
        <li key={index} className="list-group-item">
          <a href={video.url} target="_blank" rel="noopener noreferrer">
            <img src={video.thumbnail} alt={video.title} style={{ width: '150px', marginRight: '10px' }} />
            <Typography variant="body1">{video.title}</Typography>
          </a>
        </li>
      ))
    ) : (
      <Typography variant="body1" color="textSecondary" align="center">
        No video results found.
      </Typography>
    );
  };

  // Display news results
  const displayNewsResults = () => {
    return newsResults.length > 0 ? (
      newsResults.map((news, index) => (
        <li key={index} className="list-group-item">
          <a href={news.url} target="_blank" rel="noopener noreferrer">
            <Typography variant="body1">{news.title}</Typography>
          </a>
        </li>
      ))
    ) : (
      <Typography variant="body1" color="textSecondary" align="center">
        No news results found.
      </Typography>
    );
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#f4f6f8', padding: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          What's That Noise? - IT Issue Tracker
        </Typography>
      </Box>

      <Card sx={{ maxWidth: 600, mx: 'auto', p: 4, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Search for IT Issues
          </Typography>
          <form onSubmit={handleSearch}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Enter a keyword (e.g., Windows 11 issue)"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="outlined"
              />
            </Box>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Date Range</InputLabel>
              <Select
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                label="Date Range"
              >
                <MenuItem value="1d">Last 1 Day</MenuItem>
                <MenuItem value="1w">Last 1 Week</MenuItem>
                <MenuItem value="1m">Last 1 Month</MenuItem>
                <MenuItem value="6m">Last 6 Months</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSearchLocked}
            >
              {isSearchLocked ? `Please wait ${timeLeft} seconds` : 'Search'}
            </Button>
          </form>

          {errorMessage && (
            <Typography variant="body1" color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
        </CardContent>
      </Card>

      <Grid container spacing={2} sx={{ mt: 4 }}>
        {/* Web Results */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Web Results
            </Typography>
            <ul className="list-group">
              {displayWebResults()}
            </ul>
          </Card>
        </Grid>

        {/* Video Results */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" align="center" gutterBottom>
              Video Results
            </Typography>
            <ul className="list-group">
              {displayVideoResults()}
            </ul>
          </Card>
        </Grid>

        {/* News Results */}
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" align="center" gutterBottom>
              News Results
            </Typography>
            <ul className="list-group">
              {displayNewsResults()}
            </ul>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
